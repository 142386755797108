import React from 'react'
import {
  LeapContainer,
  LeapGrid,
  SectionDiv,
  Title,
  Text,
  LeapDivider,
  LeapProgram
} from './../../styles/LeapContainer.style'
import { NavUlLink } from './../../styles/NavbarDiv.style'
import {
  LeapProgramCard,
  LeapCommunityCard,
  LeapGetCard
} from './../../components/index'
import { Box, useTheme, TextField, Button, Stack } from '@mui/material'
import { homeprograms, getinvolvedcards, updates } from './data/data'

import { community } from './../community/data/data'

function HomeView() {
  const theme = useTheme()
  return (
    <main>
      <SectionDiv>
        <LeapContainer style={{ padding: '40px 10px 10px' }}>
          <LeapGrid
            sx={{
              margin: '30px 0 0'
            }}
          >
            <Box
              sx={{
                height: '450px',
                borderRadius: '4px',
                backgroundImage: `url('images/leap_banner.JPG')`,
                backgroundSize: 'cover',
                backgroudRepeat: 'no-repeat'
              }}
              className=""
            />
            <Box className="leap-banner-intro">
              <Title variant="h1">Nurturing Authentic Leadership</Title>
              <Text sx={{ padding: '10px 0 0', fontWeight: 'bold' }}>
                At Lepta we facilitate youth led community transformation by
                enhancing their skills, knowledge and experiences through
                training, mentorship and exposure to authentic leadership
                practice.
              </Text>

              <Text sx={{ padding: '20px 0 20px', fontWeight: 'bold' }}>
                Through this we believe they will have fulfilling lives as their
                abilities are unlocked, developed and engaged in their
                professional pursuits as problem solvers and drivers of change.
              </Text>
            </Box>
          </LeapGrid>

          <LeapDivider style={{ margin: '40px 0 20px' }} />

          <Box className="leap-mission">
            <Box className="title">
              <Title variant="h4">our mission</Title>
            </Box>
            <Text sx={{ lineHeight: '1.8rem' }} variant="p">
              To transform the community by creating platforms that develop,
              equip and provide opportunities for young people to maximize their
              potential.
            </Text>
          </Box>
        </LeapContainer>
      </SectionDiv>

      <SectionDiv style={{ padding: '0' }}>
        <LeapProgram
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          className="leap-program"
        >
          <>
            <Box
              sx={{
                padding: '10px 0 0',
                position: 'absolute',
                left: 0,
                bottom: 70,
                right: 0
              }}
            >
              <LeapContainer>
                <Title style={{ color: 'white' }} variant="h4">
                  our programs
                </Title>

                <LeapGrid>
                  {homeprograms.map(data => (
                    <LeapProgramCard
                      key={data.id}
                      image={data.image}
                      description={data.description}
                      style={{ margin: '0 0 0' }}
                    />
                  ))}
                </LeapGrid>
              </LeapContainer>
            </Box>
          </>
        </LeapProgram>
      </SectionDiv>

      <SectionDiv style={{}}>
        <LeapContainer>
          <Box
            sx={{
              display: 'flex',
              padding: '0 0 40px'
            }}
            className="leap-comunity-flex"
          >
            <Title variant="h4">our community</Title>

            <NavUlLink to="/ourcommunity">
              <Text
                sx={{
                  color: theme.palette.dark.main,
                  fontSize: '15px'
                }}
                variant="p"
                className="leap-community-text-btn"
              >
                Click for more
              </Text>
            </NavUlLink>
          </Box>
          <LeapGrid>
            {community.slice(0, 4).map((value, index) => (
              <LeapCommunityCard key={index} data={value} />
            ))}
          </LeapGrid>
        </LeapContainer>
      </SectionDiv>

      <SectionDiv style={{ padding: '0px 0' }}>
        <Box
          sx={{
            backgroundColor: theme.palette.dark.main,
            color: 'white !important'
          }}
        >
          <LeapContainer>
            <LeapGrid
              sx={{
                padding: '50px 0'
              }}
            >
              <Box>
                <Title variant="h4">get involved</Title>
                <Box
                  sx={{
                    backgroundColor: 'white',
                    pt: 2
                  }}
                >
                  <TextField
                    variant="filled"
                    label="Enter e-mail address"
                    sx={{
                      backgroundColor: 'white',
                      width: '100%',
                      paddingBottom: '10px'
                    }}
                    InputLabelProps={{
                      style: { color: theme.palette.warning.main }
                    }}
                    InputProps={{
                      style: {
                        color: theme.palette.warning.main
                      }
                    }}
                  />
                </Box>
                <Button variant="contained" sx={{ width: '100%', pt: 2 }}>
                  {'get started'.toLowerCase()}
                </Button>
              </Box>

              {getinvolvedcards.map((value, index) => (
                <LeapGetCard key={index} data={value} />
              ))}
            </LeapGrid>
          </LeapContainer>
        </Box>
      </SectionDiv>

      <SectionDiv style={{ padding: '10px 10px' }}>
        <LeapContainer
          style={{
            padding: '50px 0'
          }}
        >
          <Stack className="leap-updates">
            <Title variant="h4" sx={{ padding: '0 0 10px' }}>
              updates
            </Title>
            <LeapGrid>
              {updates.map((value, index) => (
                <img src={value.image} alt={value.image} />
              ))}
            </LeapGrid>
          </Stack>
        </LeapContainer>
      </SectionDiv>
    </main>
  )
}

export default HomeView
