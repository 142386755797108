import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { SharedLayout } from './components/index'
import {
  HomeView,
  CommunityView,
  GetInvolvedView,
  ErrorView,
  //   Privacy,
  AboutView
} from './views/index'

function Navigation() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SharedLayout />}>
          <Route index element={<HomeView />} />
          <Route path="whoweare" element={<AboutView />} />
          <Route path="ourcommunity" element={<CommunityView />} />
          <Route path="getinvolved" element={<GetInvolvedView />} />
          <Route path="*" element={<ErrorView />} />
          {/* <Route path="privacy" element={<Privacy />} />
          <Route path="*" element={<Error />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Navigation
