import React, { useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Stack from '@mui/material/Stack'
import { useTheme, useScrollTrigger, Slide, useMediaQuery } from '@mui/material'
import { LeapDrawer as LeapDraw } from './../index'
import { NavUlLink } from './../../styles/NavbarDiv.style'
import { LeapContainer, Text } from './../../styles/LeapContainer.style'
import { menus } from './data/data'

function HideOnScroll({ children }) {
  const trigger = useScrollTrigger()
  return <Slide in={!trigger}>{children}</Slide>
}

function LeapNavbar() {
  const [state, setState] = useState(false)

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState(open)
  }
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.up('sm'))
  return (
    <React.Fragment data-testid="navbar">
      <HideOnScroll>
        <Box
          sx={{
            flexGrow: 1,
            height: '90px',
            width: '100%',
            position: 'fixed',
            zIndex: '999'
          }}
        >
          <AppBar position="static">
            <LeapContainer>
              <Toolbar
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <NavUlLink to="/">
                  <img
                    src={'./images/leap_logo.png'}
                    alt={'Logo'}
                    style={{ height: '80px', padding: '10px 0' }}
                  />
                </NavUlLink>

                {isMobile ? (
                  <Stack direction="column" sx={{ justifyContent: 'center' }}>
                    <Text
                      sx={{
                        color: theme.palette.warning.main,
                        textAlign: 'center'
                      }}
                    >
                      "a transformed community of leaders"
                    </Text>
                    <Stack
                      direction="row"
                      sx={{ justifyContent: 'center' }}
                      spacing={4}
                    >
                      {menus.map((value, index) => (
                        <>
                          <NavUlLink key={index} to={value.route}>
                            <Text>{value.name.toUpperCase()}</Text>
                          </NavUlLink>
                        </>
                      ))}
                    </Stack>
                  </Stack>
                ) : (
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 0 }}
                    onClick={toggleDrawer('left', true)}
                  >
                    <MenuIcon />
                  </IconButton>
                )}
              </Toolbar>
            </LeapContainer>
          </AppBar>
        </Box>
      </HideOnScroll>
      <LeapDraw
        open={state}
        menus={menus}
        handleDrawer={toggleDrawer('left', false)}
      />
    </React.Fragment>
  )
}

export default LeapNavbar
