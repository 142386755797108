import { createGlobalStyle } from 'styled-components'
import RegularFont from './../assets/fonts/Nexa-Regular.otf'
import LightFont from './../assets/fonts/Nexa-Light.otf'
import BoldFont from './../assets/fonts/Nexa-Bold.otf'

const GlobalStyles = createGlobalStyle`
  @font-face {
        font-family: 'Nexa';
        src: url(${RegularFont}) format('truetype');
        font-style: normal;
        font-display: auto;
    }

    @font-face {
        font-family: 'Nexa-Bold';
        src: url(${BoldFont}) format('truetype');
        font-style: normal;
        font-display: auto;
    }

    @font-face {
        font-family: 'Nexa-Light';
        src: url(${LightFont}) format('truetype');
        font-style: normal;
        font-display: auto;
    }

    * {
        box-sizing: border-box;
        paddding: 0;
        margin: 0;
    }

    html, body {
        font-family: 'Nexa-Light', sans-serif;
        font-size: 1.05em;

        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }

    img {
        max-width: 100%;
    }

    .leapnav-anime {
        
    }

    .leap-mission > h1 {
      width: 40% !important;

      @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px){
        width: 100%;
      }
    }

    .leap-mission {
        display: flex;
        flex-direction: row;
    }

    .leap-mission .title {
        width:40%;
    }

    .leap-program-img {
        width: 20%;
        height: 120px;
        padding-right: 40px;
    }

    .leap-program-description {
        width: 80%;
    }

    .leap-program-image{
        height: 125px;
        width: 75%;
    }

    .leap-program-flex {
        flex-direction: row !important;
    }

    .leap-alumni-avatar {
        object-fit: cover;
        border-radius:50%;
        width: 100%;
        height: 100%;
    }

    .hide-scroll::-webkit-scrollbar {
        display: none;
    }

    .about_image {
        width: 100%;
    }

    .leap-socials {
        list-style: none;
    }

    .leap-socials li {
        margin: 0 20px;
    }

    .get-socials {
        color: ${({ theme }) => theme.palette.dark.main};
        text-decoration: none;
    }

    .get-socials::hover {
        
        text-decoration: none;
        
    }

    .MuiInputBase-input  {
        background: beige;
    }

    .leap-comunity-flex {
        justify-content: space-between;
        flex-direction: row !important;
    }

    .leap-updates {
        flex-direction: row !important;
        justify-content: space-between !important;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        .leap-banner-intro {
            grid-row-start: 1;
        }
        
        .leap-banner, .leap-banner-intro {
            width: 100% !important;
        }

        .leap-mission {
            flex-direction: column;
        }

        .leap-mission, .title {
            width: 100% !important;
        }

        .leap-program-flex {
            flex-direction: column !important;
        }

        .leap-program-description {
            width: 100%;
            pading-top: 10px !important;
        }

        .leap-program-img {
            width: 100%;
            height: 120px;
            padding-right: 55px;
        }

        .get-img {
            height: 120px;
            margin: 0 auto;
        }

        .leap-community-text-btn {
            display: none;
        }

        .leap-comunity-flex {
            justify-content: space-between;
            flex-direction: column !important;
        }

        .leap-updates {
            flex-direction: column !important;
        }
    }
`

export default GlobalStyles
