import React from 'react'
import { Box, useTheme } from '@mui/material'
import { Text } from '../../styles/LeapContainer.style'
import { NavUlLink } from './../../styles/NavbarDiv.style'

const LeapGetCard = props => {
  const theme = useTheme()
  return (
    <Box>
      <Box
        sx={{
          textAlign: 'center',
          backgroundColor: theme.palette.warning.main,
          borderRadius: '10px',
          textTransform: 'uppercase',
          fontSize: '10px',
          padding: '10px 0',
          fontWeight: '600',
          margin: '0 0 20px'
        }}
      >
        <NavUlLink to="/getinvolved">
          <Text>{props.data.title}</Text>
        </NavUlLink>
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <Text>{props.data.body}</Text>
      </Box>
    </Box>
  )
}

export default LeapGetCard
