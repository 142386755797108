import React from 'react'
import Lottie from 'lottie-react'

const LeapLottie = props => {
  return (
    <>
      <Lottie animationData={props.animationData} loop={true} />
    </>
  )
}

export default LeapLottie
