import React from 'react'
import { Box } from '@mui/material'
import { Text } from '../../styles/LeapContainer.style'

const LeapProgramCard = props => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        textAlign: 'center'
      }}
    >
      <Box
        sx={{
          alignSelf: 'center'
        }}
        className="leap-program-image leap_program_img"
      >
        <img src={props.image} alt={props.image} />
      </Box>

      <Box
        sx={{
          backgroundColor: '#FFFFFF',
          textAlign: 'center',
          padding: '10px 0',
          margin: '25px 0 0',
          borderRadius: '10px'
        }}
      >
        <Text>{props.description}</Text>
      </Box>
    </Box>
  )
}

export default LeapProgramCard
