import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { LeapNavbar, LeapFooter } from './../index'

function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

function SharedLayout() {
  return (
    <>
      <LeapNavbar />
      <ScrollToTop />
      <Outlet />
      <LeapFooter />
    </>
  )
}

export default SharedLayout
