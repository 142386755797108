import React from 'react'
import {
  Box,
  useTheme,
  Drawer,
  List,
  ListItem,
  ListItemButton
} from '@mui/material'
import { Text } from '../../styles/LeapContainer.style'
import { NavUlLink } from './../../styles/NavbarDiv.style'

const LeapDrawer = props => {
  const theme = useTheme()
  return (
    <Drawer
      anchor={'left'}
      open={props.open}
      onClick={props.handleDrawer}
      sx={{}}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          height: 'inherit',
          display: 'flex',
          width: 350,
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <NavUlLink
          to="/"
          onClick={props.handleDrawer}
          style={{ padding: '10px 0 0' }}
        >
          <img
            src={'./images/leap_logo.png'}
            alt={'Logo'}
            style={{ height: '80px', padding: '10px 0' }}
          />
        </NavUlLink>
        <List
          sx={{
            flex: 1,
            justifyContent: 'center',
          }}
        >
          {props.menus.map((value, index) => (
            <ListItem key={index}>
              <ListItemButton onClick={props.handleDrawer}>
                <NavUlLink to={value.route}>
                  <Text sx={{
                    textAlign: 'center'
                  }}>{value.name.toUpperCase()}</Text>
                </NavUlLink>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  )
}

export default LeapDrawer
