import styled from 'styled-components'
import { Grid, Typography, Box } from '@mui/material'
export const LeapContainer = styled('div')`
  width: 1000px;
  max-width: 100%;
  padding: 0 20px;
  margin: 0 auto;
`

export const LeapGrid = styled(Grid)`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
  grid-auto-rows: 1fr;

  @media (max-width: 600px) {
    gap: 10px;
    justify-content: center;
  }
`

export const SectionDiv = styled.section`
  padding: 40px 0;
`

export const Title = styled(Typography)`
  font-family: 'Nexa', sans-serif;
  font-weight: 300 !important;
  //font-weight: bold;
  color: ${({ theme }) => theme.palette.warning.main};
`

export const Text = styled(Typography)`
  font-family: 'Nexa-Light', sans-serif !important;
  font-weight: bold;
  line-height: 2rem;
`

export const LeapDivider = styled.div`
  background-color: ${({ theme }) => theme.palette.warning.main};
  width: 100%;
  height: 3px;
`

export const LeapProgram = styled.div`
  background-color: ${({ theme }) => theme.palette.white.main};
  background: url('./images/programs_img_background.png') no-repeat center
    center/cover;
  position: relative;
  height: 500px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    height: 930px;
  }
`

//Footer
export const FooterDiv = styled(Box)`
  background-color: ${({ theme }) => theme.palette.dark.main};
  color: white;
  padding: 40px 0;
`
