import React from 'react'
import { LeapContainer, SectionDiv } from './../../styles/LeapContainer.style'
import { Box } from '@mui/material'

import { LeapLottie } from '../../components/index'
import animationData from './../../assets/lottie/error_f.json'

export default function ErrorView() {
  return (
    <SectionDiv>
      <LeapContainer style={{ padding: '40px 10px 10px' }}>
        <Box>
          <Box className="container">
            <LeapLottie animationData={animationData} key={1} />
          </Box>
        </Box>
      </LeapContainer>
    </SectionDiv>
  )
}
