export const homeprograms = [
  {
    id: 1,
    image: './images/kiota.png',
    count: 1700,
    description: '1700 pupils engaged'
  },
  {
    id: 2,
    image: './images/leap.png',
    count: 155,
    description: '155 trained'
  },
  {
    id: 3,
    image: './images/leads.png',
    count: 4,
    description: '4 Counties trained (210)'
  }
]

export const getinvolvedcards = [
  {
    id: 1,
    title: 'collaborate',
    body: 'Do you want to start running this program in your center/community'
  },
  {
    id: 3,
    title: 'learn',
    body: 'Do you want to undergo our training program'
  },
  {
    id: 4,
    title: 'support',
    body: 'Do you want to support our work'
  }
]

export const updates = [
  {
    image: './images/post_1.png'
  },
  {
    image: './images/post_2.png'
  },
  {
    image: './images/post_3.jpg'
  }
]
