import React from 'react'
import { Box, Stack } from '@mui/material'
import { Text, Title } from '../../styles/LeapContainer.style'

const LeapTeam = props => {
  return (
    <Stack
      data-testid="team"
      direction="column"
      spacing="2"
      sx={{
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          width: '150px',
          height: '150px'
        }}
      >
        <img
          src={
            'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YXZhdGFyfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=700&q=60'
          }
          alt={'logo'}
          className="leap-alumni-avatar"
        />
      </Box>
      <Title className="purple" style={{ color: '#33094D' }}>
        {props.data.name}
      </Title>
      <Text variant="p">{props.data.title.toUpperCase()}</Text>
    </Stack>
  )
}

export default LeapTeam
