import React from 'react'
import {
  FooterDiv,
  LeapContainer,
  LeapGrid,
  Title,
  Text,
  LeapDivider
} from './../../styles/LeapContainer.style'
import { Box, Stack, useTheme } from '@mui/material'
import { NavUlLink } from './../../styles/NavbarDiv.style'

import { BsFacebook, BsInstagram } from 'react-icons/bs'
import { AiFillTwitterCircle } from 'react-icons/ai'
import { GrLinkedin } from 'react-icons/gr'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'
import { BsFillTelephoneFill } from 'react-icons/bs'

const LeapFooter = () => {
  const theme = useTheme()
  return (
    <FooterDiv>
      <LeapContainer>
        <LeapGrid>
          <div>
            <Title
              className="leap-footer-t1"
              variant="h4"
              style={{ fontSize: '1.6rem', margin: '0 0 35px' }}
            >
              our partners
            </Title>
            <div>
              <img src={'./images/our_patners.png'} alt={'Logo'} />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '0 0 0',
              margin: '0 0 10px'
            }}
          >
            <Box style={{}}>
              <img
                src={'./images/lepta_community.png'}
                alt={'Logo'}
                height="120"
                style={{ margin: '0 0 0px' }}
              />
            </Box>

            <LeapDivider
              style={{
                backgroundColor: 'white',
                margin: '15px 0',
                width: '80%'
              }}
            />
            <Title
              style={{ padding: '10px 0 10px', fontSize: '20px' }}
              variant="h4"
            >
              Let's Connect
            </Title>

            <Stack
              direction="row"
              className="leap-socials"
              style={{
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <li>
                <NavUlLink to={{}} rel="noopener noreferrer" target="_blank">
                  <a
                    rel="noopener noreferrer"
                    href="https://facebook.com/leptacommunity"
                    target="_blank"
                  >
                    <BsFacebook size="30px" style={{ color: 'white' }} />
                  </a>
                </NavUlLink>
              </li>

              <li>
                <NavUlLink to={{}} rel="noopener noreferrer" target="_blank">
                  <a
                    rel="noopener noreferrer"
                    href="https://instagram.com/leptacommunity"
                    target="_blank"
                  >
                    <BsInstagram size="30px" style={{ color: 'white' }} />
                  </a>
                </NavUlLink>
              </li>

              <li>
                <NavUlLink to={{}} rel="noopener noreferrer" target="_blank">
                  <a
                    rel="noopener noreferrer"
                    href="https://twitter.com/leptacommunity"
                    target="_blank"
                  >
                    <AiFillTwitterCircle
                      size="30px"
                      style={{ color: 'white' }}
                    />
                  </a>
                </NavUlLink>
              </li>
              <li>
                <NavUlLink to={{}} rel="noopener noreferrer" target="_blank">
                  <a
                    rel="noopener noreferrer"
                    href="https://linkedin.com/company/lepta-community"
                    target="_blank"
                  >
                    <GrLinkedin size="30px" style={{ color: 'white' }} />
                  </a>
                </NavUlLink>
              </li>
            </Stack>

            <Text
              style={{
                display: 'flex',
                padding: '20px 0 0',
                textAlign: 'center',
                justifyContent: 'center',
                fontSize: '.8rem'
              }}
            >
              <NavUlLink
                to={{}}
                rel="noopener noreferrer"
                target="_blank"
                style={{ fontSize: '.8rem' }}
              >
                <a
                  rel="noopener noreferrer"
                  href="https://leptacommunity.org"
                  target="_blank"
                >
                  <Text
                    variant="p"
                    style={{
                      color: theme.palette.warning.main,
                      textDecoration: 'underline'
                    }}
                  >
                    leptacommunity.org
                  </Text>
                </a>
              </NavUlLink>
              <span>&nbsp;&copy; {new Date().getFullYear()}</span>
            </Text>
          </div>

          <Stack direction="column" sx={{}}>
            <Title
              className="footer_title"
              style={{
                fontSize: '1.6rem',
                textAlign: 'center',
                margin: '0 0 0 40px'
              }}
              variant="h4"
            >
              quick links
            </Title>

            <Box style={{ width: '100%' }}>
              <ul
                className="leap-quick-links leap-socials"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                  justifyContent: 'center',
                  fontSize: '.8rem',
                  color: 'white'
                }}
              >
                {/* <li>
                <NavUlLink to={{}} rel="noopener noreferrer" target="_blank">
                  <a
                    rel="noopener noreferrer"
                    href="https://leptacommunity.org"
                    target="_blank"
                  >
                    Lepta community
                  </a>
                </NavUlLink>
              </li> */}
                <li>
                  <NavUlLink to={{}} rel="noopener noreferrer" target="_blank">
                    <a
                      rel="noopener noreferrer"
                      href="tel:+254702846379"
                      target="_blank"
                    >
                      <BsFillTelephoneFill
                        size="30px"
                        style={{
                          color: 'white',
                          paddingTop: '10px'
                        }}
                      />
                      <Text
                        color="white"
                        style={{ fontSize: '.8rem', color: 'white' }}
                      >
                        +254 702 846 379
                      </Text>
                    </a>
                  </NavUlLink>
                </li>
                <li>
                  <NavUlLink to={{}} rel="noopener noreferrer" target="_blank">
                    <a
                      rel="noopener noreferrer"
                      href="mailto:info@leptacommunity.org"
                      target="_blank"
                    >
                      <MdEmail
                        size="30px"
                        style={{
                          color: 'white',
                          paddingTop: '10px'
                        }}
                      />
                      <Text
                        color="white"
                        style={{ fontSize: '.8rem', color: 'white' }}
                      >
                        info@leptacommunity.org
                      </Text>
                    </a>
                  </NavUlLink>
                </li>
                <li>
                  <NavUlLink to={{}} rel="noopener noreferrer" target="_blank">
                    <a
                      rel="noopener noreferrer"
                      href="https://goo.gl/maps/MeGMfGWCqDPVWkuE9"
                      target="_blank"
                    >
                      <FaMapMarkerAlt
                        size="30px"
                        style={{ color: 'white', paddingTop: '10px' }}
                      />
                      <Text
                        color="white"
                        style={{ fontSize: '.8rem', color: 'white' }}
                      >
                        Nairobi, Kenya
                      </Text>
                    </a>
                  </NavUlLink>
                </li>
                <li style={{ padding: '20px 0 20px' }}>
                  <NavUlLink to="/privacy" style={{ fontSize: '.8rem' }}>
                    Privacy Policy
                  </NavUlLink>
                </li>
              </ul>
            </Box>
          </Stack>
        </LeapGrid>
      </LeapContainer>
    </FooterDiv>
  )
}

export default LeapFooter
