import * as React from 'react'
import {
  Box,
  Modal,
  useTheme,
  Backdrop,
  Fade,
  useMediaQuery
} from '@mui/material'

export default function LeapModal(props) {
  const theme = useTheme()
  const isMobile = useMediaQuery(
    `(min-width: ${theme.breakpoints.values.sm}px)`
  )
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
      >
        <Fade in={props.open}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',

              transform: 'translate(-50%, -50%)',
              width: isMobile ? 300 : 400,
              height: isMobile ? 400 : 600,
              backgroundColor: theme.palette.primary.main,
              borderRadius: '10px',
              boxShadow: 24,
              p: 4,

              color: 'white',

              overflowY: 'scroll'
            }}
            className="hide-scroll"
          >
            <Box
              sx={{
                height: 'inherit',
                maxHeight: '400px',
                color: 'white !important'
              }}
            >
              {props.children}
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
