import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const NavUlLink = styled(NavLink)`
  text-decoration: none;
  color: white;
  transition: 0.5s;
  position: reletive;

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 3px;
    top: 100%;
    left: 0;
    transition: transform 0.5s;
    transform: scaleX(0);
    transform-origin: right;
  }

  &:hover {
    color: ${({ theme }) => theme.palette.warning.main};
  }

  &:hover::after {
    transform: scaleX(1);
    transform-origin: left;
  }
`
