import React from 'react'
import {
  LeapContainer,
  SectionDiv,
  Text,
  Title,
  LeapGrid,
  LeapDivider
} from './../../styles/LeapContainer.style'
import { Box, useTheme, Stack } from '@mui/material'
import { images, programs, team } from './data/data'
import { LeapTeam } from './../../components/index'

function AboutView() {
  const theme = useTheme()

  return (
    <main>
      <SectionDiv>
        <LeapContainer
          style={{
            padding: '40px 10px 10px'
          }}
        >
          <Box
            sx={{
              margin: '30px 0 0'
            }}
          >
            <Text style={{ lineHeight: '1.8rem' }} variant="p">
              Lepta community was registered in 2006 and has been working in
              Mathare slums and its environs inspired by a vision of...{'  '}
              <span>"A transformed community of leaders"</span>
            </Text>
            <br />
            <br />
            {''}
            <Text
              style={{ lineHeight: '1.8rem', margin: '20px 0 0' }}
              variant="p"
            >
              We believed and still do that the underlying problem is not just
              an external one rather it is to a greater extent internal. By this
              we mean the lack of self-belief, ignorance, emptiness, a feeling
              of powerlessness, wrong attitudes, etc. It is central that any
              true and lasting transformation must focus on the people and not
              just the structures around them. This theme of people development
              is core to all our operations and inspires our vision of{' '}
              <span>"a transformed community of leaders".</span>
            </Text>
            <br />
            <br />

            <Text
              style={{ lineHeight: '1.8rem', margin: '20px 0 0' }}
              variant="p"
            >
              Having operated in Mathare since 2006, we realize that even those
              that do not live in “slums” still face the same internal
              constraints that limit them in life. Our vision and reach has
              continued to expand and we have now cast our sight beyond the
              borders of Mathare, with a desire to bring change to Africa and
              the rest of the world.
            </Text>

            <br />
            <br />

            <Title
              variant="h4"
              sx={{
                color: theme.palette.primary.main
              }}
            >
              Treasure hunters
            </Title>

            <Text variant="p">
              Lepta community is about changing the lives of the people
              especially those living in hardship. As we are convinced that are
              one of the greatest treasure fields on earth. Under the many
              layers lies a great treasure hidden within them. Lepta is about
              finding that treasure. We are digging and are determined that the
              dirt and rocks will not stop us as we are convinced every person
              can realize their full potential and rise up as a light to impact
              their world.
            </Text>
          </Box>
        </LeapContainer>
      </SectionDiv>

      <SectionDiv style={{ padding: '0 0' }}>
        <LeapContainer>
          <Title
            variant="h4"
            sx={{
              margin: '0 0 30px'
            }}
          >
            our approach
          </Title>
        </LeapContainer>

        <Box
          sx={{
            margin: '0 0 20px',
            padding: '30px 0',
            backgroundColor: theme.palette.dark.main
          }}
        >
          <LeapContainer>
            <Title
              variant="h4"
              sx={{
                color: 'white !important',

                fontFamily: 'Nexa-Light'
              }}
            >
              <span
                style={{
                  fontWeight: 'bold',
                  fontFamily: 'Nexa-Bold'
                }}
              >
                ESC
              </span>
              Leadership
            </Title>
            <br />
            <LeapGrid
              sx={{
                padding: '20px 0 0'
              }}
            >
              {images.map(e => (
                <img src={e.image} alt={''} className="about_image" />
              ))}
            </LeapGrid>
          </LeapContainer>
        </Box>
      </SectionDiv>

      <SectionDiv>
        <LeapContainer>
          <Title style={{ margin: '25px 0' }} variant="h4">
            our programs
          </Title>
          <Text style={{ margin: '20px 0' }}>
            For the organization to achieve its goals, each individual
            participant in our programs has to go through leadership training
            and mentorship that covers self-awareness, personal and leadership
            development, employability and entrepreniual skills complemented by
            exposure, practicums, volunteerism
          </Text>

          <Box
            sx={{
              margin: '20px 0 0'
            }}
          >
            {programs.map((e, index) => (
              <Stack
                className="leap-program-flex"
                sx={{
                  margin: '20px 0',
                  justifyContent: 'space-between'
                }}
              >
                <Box className="leap-program-img">
                  <img src={e.image} alt={e.label} />
                </Box>
                <Box className="leap-program-description">
                  <Text style={{}}>{e.description}</Text>
                </Box>
              </Stack>
            ))}
          </Box>

          <LeapDivider />

          <Title
            variant="h4"
            sx={{
              padding: '20px 0 10px'
            }}
          >
            our team
          </Title>
          <LeapGrid style={{}}>
            {team.map(e => (
              <LeapTeam data={e} />
            ))}
          </LeapGrid>
        </LeapContainer>
      </SectionDiv>
    </main>
  )
}

export default AboutView
