export const community = [
  {
    id: 1,
    name: 'Rose Kavuli',
    role: 'Class of 2008 Founding team member 2006',
    description: `I am currently the Operations Manager- New Communities at Fresh Life Initiative where I lead the operations to ensure that people have access to affordable hygienic sanitation solutions in low income communities. I thrive in development work, nature related ventures and creative writing. I do my best every day to improve the quality of life of people living in low income communities. This drives my daily engagements that can be described by excellence and authenticity. My mantra is be the change that you want.`,
    image: './images/alumni_1.jpg'
  },
  {
    id: 2,
    name: 'Kristina Grande',
    role: 'Class of 2010',
    description: `I am an addiction and mental health counselor, working especially with urban youth. I want to live in a world where every person is whole, confident, and part of a loving community.`,
    image: './images/alumni_2.jpg'
  },
  {
    id: 3,
    name: 'Francis Gicharu Njoroge',
    role: 'Leads Class of 2022',
    description: `I am a resilient and hardworking person who is very passionate about positive change in the community. I currently work with Dignitas- a leading education development organization in Kenya as a Program Officer where I manage special programs including a girls’ scholarship program and other student support projects like the uniform distributions to school going children. I also lead a teens program called Ignite Teens Program,where we empower young people through life skills training. I believe in the power of education to change lives and I would like to see a world where every child has access to education and are empowered to become change agents who live to their full potential.`,
    image: './images/alumni_3.jpg'
  },

  {
    id: 4,
    name: 'Omucheyi Vincent',
    role: 'Class of 2018',
    description: `I am currently one of the directors at
Unlock Potential Enterprises, i oversea
all operations on a daily basis, also in
charge of volunteer welfare, where i
make sure every single need are well
taken care of to enable them work in a
more peaceful, serene and conducive
niche.
I want to see a world where young
people take centre stage in all spheres
of professionalism moreso in unlocking
and nurturing skills of other young
people. This will ensure the world has a
wider, more smarter and diversified
skillset of people for future endeavors.
I believe as much as academia is a very
pivotal point in this journey other areas
such as talents and gifts remain under
utilized, if well explored we moght solve
the big unemployment question, and
thats my goal.`,
    image: './images/alumni_4.jpg'
  },

  {
    id: 6,
    name: 'Mercy Munga',
    role: 'Leap Class of 2014',
    description: `I am currently a pharmaceutical
technologist working as a self employed
business person at The Maverick&#39;s
Pharmacy. 
I run the business as well as do medical
charity to those unable to afford proper
care.
I see myself in a world where a higher
percentage of the population is healthy,
and people get equal or close to equal
medical attention at very affordable
prices.`,
    image: './images/alumni_6.jpg'
  },
  {
    id: 7,
    name: 'Wanjiku Kamau',
    role: 'Class of 2015',
    description: `I am currently doubling up as the
Finance officer and program
Lead,Elimisha Vizazi  at  Footprints For
Change
where I harmonize daily budget
transactions . Under Elimisha Vizazi we
sensitize communities on the
importance of cultures of peace through
community youth led events.
I am living to see a world where
organizations run in authentic and
accountable financial systems fueling
growth. Also,I want to see a community
that is a safe space for diversified
expressions promoting peace.`,
    image: './images/alumni_7.jpg'
  },
  {
    id: 8,
    name: 'Isaack Wambua',
    role: 'Class of 2021',
    description: `With a background in photography,
filmmaking &amp; journalism, I&#39;m a
passionate, creative person who is
determined to achieve objectives.  
I am a freelance working with different
organizations to document their day to
day activities. I also offer photography
and videography training to youths in my
community so a to create social impact.
My focus in life has been based around
building and growing communities. I feel
happy helping people directly, especially
when I can help them accomplish their
goals. My desire is to continue the same
aid with my abilities in photography,
filmmaking, video editing and any other
digital medium.`,
    image: './images/alumni_8.jpg'
  },
  {
    id: 9,
    name: 'Peter Otieno',
    role: 'Class of 2012',
    description: `I am the lead interior designer and founder
of Elle creations. An interior design firm
that deals with all cabinetry and joinery
works. 
With over four years of experience in the
construction industry I take pride in creating
elegant Bespoke kitchens and  wardrobes. 
I have always had a passion for ART in
general, hence using my skills gained over
the years to train kids in drawing and
painting at RECREATION SCHOOL OF
ART.
I want to see a world where individuals
express themselves in different forms of arts
to give value in society.`,
    image: './images/alumni_9.jpg'
  },
  {
    id: 10,
    name: 'Collins Otieno Odhiambo',
    role: 'Class of 2022',
    description: `I'm an Entrepreneur, I do production of
peanut butter and also Business Coach.
I&#39;m the current Chairperson of KUCSA
(Kibra University College Students
Alumni Association) and also a Board
member of Association of Alumni
Communities in Kenya (AACK)
I want to live in a world where the youth
have the liberty to venture in any good
income generating activities as they
grow to become good role models to the
coming generations`,
    image: './images/alumni_10.jpg'
  }
]
