import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import RegularFont from './assets/fonts/Nexa-Regular.otf'
import LightFont from './assets/fonts/Nexa-Light.otf'
import BoldFont from './assets/fonts/Nexa-Bold.otf'
import Navigation from './Navigation'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import GlobalStyles from './styles/GlobalStyle'

const regularFont = {
  fontFamily: 'Nexa',
  src: `url(${RegularFont}) format('truetype')`
}

const lightFont = {
  fontFamily: 'Nexa-Light',
  src: `url(${BoldFont}) format('truetype')`
}

const boldFont = {
  fontFamily: 'Nexa-Bold',
  src: `url(${LightFont}) format('truetype')`
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#33094D'
    },
    secondar: {
      main: '#C7C9CB'
    },
    dark: {
      main: '#1A1A1A'
    },
    warning: {
      main: '#CDAA3C',
      light: '#b39d59'
    },
    light: {
      main: '#F5F2DF'
    },
    white: {
      main: '#FFFFFF'
    }
  },
  typography: {
    fontFamily: ['"Nexa"', 'sans-serif'].join(','),
    h1: {
      fontSize: '2.9rem',
      fontFamily: 'Nexa',
      fontWeight: '300'
    },
    body1: {
      fontWeight: 'bold',
      lineHeight: '2rem'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 810,
      lg: 1200,
      xl: 1536
    }
  },
  components: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [regularFont, lightFont, boldFont]
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: 'green',
        color: 'red'
      }
    }
  }
})
const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <StyledThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        <Navigation />
      </StyledThemeProvider>
    </ThemeProvider>
  )
}

export default App
