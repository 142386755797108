export const images = [
  {
    id: 1,
    image: './images/learning.png'
  },
  {
    id: 2,
    image: './images/growth.png'
  },
  {
    id: 3,
    image: './images/support.png'
  }
]

export const programs = [
  {
    id: 1,
    label: 'kiota',
    image: './images/kiota_black.png',
    description: `This is a program for primary and junior high school going children designed in line with our vision to
provide a platform where children receive training and mentorship sessions to develop their life skills. In
addition, we believe it's important for young people to discover early on that they have the ability to
drive positive change in their community`
  },
  {
    id: 2,
    label: 'leap',
    image: './images/leap_black.png',
    description: `This is a post high school program for 18-21 year olds where students are prepared to transition fully to
life after school and into adulthood. Our work is to ensure that they have the right tools to succeed in
what they discover through the program as their next steps in life`
  },
  {
    id: 3,
    label: 'leads',
    image: './images/leads_black.png',
    description: `The program was developed to cater for short term training programs, usually 10 sessions for individuals
or organizations and young professionals seeking to undergo the ECS Leadership Curriculum. The
Leadership and Skills Development Program is designed to prepare them for impact in the professional
life`
  }
]

export const team = [
  {
    id: 1,
    name: 'steve',
    title: 'board chairperson'
  },
  {
    id: 2,
    name: 'jking',
    title: 'executive director'
  },
  {
    id: 3,
    name: 'seth',
    title: 'program manager'
  },
  {
    id: 3,
    name: 'seth',
    title: 'program manager'
  },
  {
    id: 3,
    name: 'seth',
    title: 'program manager'
  },
  {
    id: 3,
    name: 'seth',
    title: 'program manager'
  },
  {
    id: 3,
    name: 'seth',
    title: 'program manager'
  },
  {
    id: 3,
    name: 'seth',
    title: 'program manager'
  },
  {
    id: 3,
    name: 'seth',
    title: 'program manager'
  },
  {
    id: 3,
    name: 'seth',
    title: 'program manager'
  },
  {
    id: 3,
    name: 'seth',
    title: 'program manager'
  },
  {
    id: 3,
    name: 'seth',
    title: 'program manager'
  },
  {
    id: 3,
    name: 'seth',
    title: 'program manager'
  },
  {
    id: 3,
    name: 'seth',
    title: 'program manager'
  },
  {
    id: 3,
    name: 'seth',
    title: 'program manager'
  },
  {
    id: 3,
    name: 'seth',
    title: 'program manager'
  }
]
