import React, { useState } from 'react'
import { Box, useTheme, Button } from '@mui/material'
import { Text } from '../../styles/LeapContainer.style'
import { LeapModal } from '../../components/index'

const LeapCommunityCard = props => {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <div>
      <Box
        sx={{
          padding: '10px',
          backgroundColor: theme.palette.warning.main,
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            height: '150px',
            width: '150px'
          }}
        >
          <img
            src={props.data.image}
            alt={props.data.image}
            className="leap-alumni-avatar"
          />
        </Box>
        <Box
          sx={{
            textAlign: 'center'
          }}
        >
          <Text>{props.data.name}</Text>
          <Text>{props.data.role}</Text>
        </Box>
        <Button variant="contained" sx={{ width: '100%' }} onClick={handleOpen}>
          Learn More
        </Button>

        <LeapModal
          children={
            <Text
              variant="p"
              sx={{
                color: 'white'
              }}
            >
              {props.data.description}
            </Text>
          }
          open={open}
          handleClose={handleClose}
          handleOpen={handleOpen}
        />
      </Box>
    </div>
  )
}

export default LeapCommunityCard
