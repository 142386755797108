export const menus = [
  {
    id: 1,
    name: 'who we are',
    route: '/whoweare'
  },
  {
    id: 2,
    name: 'get involved',
    route: '/getinvolved'
  },
  {
    id: 3,
    name: 'our community',
    route: '/ourcommunity'
  }
]
