import React from 'react'
import {
  LeapContainer,
  SectionDiv,
  Text,
  Title,
  LeapDivider
} from './../../styles/LeapContainer.style'
import { Box, useTheme, Stack } from '@mui/material'
import { NavUlLink } from './../../styles/NavbarDiv.style'

function GetInvolvedView() {
  const theme = useTheme()
  return (
    <SectionDiv>
      <LeapContainer
        style={{
          padding: '40px 10px 10px'
        }}
      >
        <Title style={{ padding: '20px 0 20px' }} variant="h4">
          get involved
        </Title>

        <Text variant="p">
          At lepta we believe that all human beings from all walks of life
          should be provided with platforms and opportunities that allow them to
          live fulfilling and successful lives. The Young people we serve from
          vulnerable and underserved communities such as Mathare slums and its
          environs need the services that we provide through our programs to
          facilitate alternative futures for them.
        </Text>

        <Title
          style={{
            margin: '20px 0 0',
            fontWeight: 'bold',
            fontFamily: 'Nexa-Bold',
            color: theme.palette.primary.main
            // fontSize: '1.2rem'
          }}
          variant="h6"
        >
          Lets come together to make this a reality for them
        </Title>
        <Text style={{ margin: '20px 0 30px' }}>
          Follow us on our socials at{' '}
          <span>
            <NavUlLink to={{}} rel="noopener noreferrer" target="_blank">
              <a
                className="get-socials"
                rel="noopener noreferrer"
                href="https://facebook.com/leptacommunity"
                target="_blank"
              >
                Facebook
              </a>
            </NavUlLink>
          </span>
          ,{' '}
          <span>
            <NavUlLink to={{}} rel="noopener noreferrer" target="_blank">
              <a
                className="get-socials"
                rel="noopener noreferrer"
                href="https://instagram.com/leptacommunity"
                target="_blank"
              >
                Instagram
              </a>
            </NavUlLink>
          </span>
          ,{' '}
          <span>
            <NavUlLink to={{}} rel="noopener noreferrer" target="_blank">
              <a
                className="get-socials"
                rel="noopener noreferrer"
                href="https://twitter.com/leptacommunity"
                target="_blank"
              >
                Twitter
              </a>
            </NavUlLink>
          </span>
          {' and '}
          <span>
            <NavUlLink to={{}} rel="noopener noreferrer" target="_blank">
              <a
                className="get-socials"
                rel="noopener noreferrer"
                href="https://linkedin.com/company/lepta-community"
                target="_blank"
              >
                Linkedin
              </a>
            </NavUlLink>
          </span>{' '}
          to receive real-time updates.
        </Text>

        <LeapDivider />

        <Box>
          <Stack
            className="leap-program-flex"
            direction="row"
            style={{
              margin: '30px 0 40px',
              justifyContent: 'space-between'
            }}
          >
            <Box className="leap-program-img">
              <img
                src={'./images/support_icon.png'}
                alt={'Logo'}
                className="get-img"
              />
            </Box>
            <Box className="leap-program-description">
              <Text style={{}}>
                Our programs are offered for free and hence your gifts and
                donations support the training and mentorship programs and
                workshops. Your donations will also help in the following ways:
                <ol type="a">
                  <li>
                    provide seed funding for the startups initiated by students
                  </li>
                  <li>allow students to advance in their education</li>
                  <li>
                    build a movement through a continuous alumni engagement and
                    support
                  </li>
                </ol>
                You can Support through individual donations, donating your
                birthday, by fundraising for us and/or as a corporate body. For
                donations or other questions, please reach out to us by sending
                an email to the director at{' '}
                <NavUlLink to={{}} rel="noopener noreferrer" target="_blank">
                  <a
                    rel="noopener noreferrer"
                    href="mailto:jngare@leptacommunity.org"
                    target="_blank"
                  >
                    jngare@leptacommunity.org
                  </a>
                </NavUlLink>{' '}
                or call +254 725 554 478 for banking information. as a supporter
                we will share a newsletter yearly with updates on our program
                outcomes
              </Text>
            </Box>
          </Stack>

          <LeapDivider />

          <Stack
            className="leap-program-flex"
            style={{
              margin: '30px 0 40px',
              justifyContent: 'space-between'
            }}
          >
            <Box className="leap-program-img">
              <img
                src={'./images/collaborate_icon.png'}
                alt={'Logo'}
                className="get-img"
              />
            </Box>

            <Box className="leap-program-description">
              <Text>
                Do to you want to start running this programs in your
                center/community?
                <br /> We believe in the power of cooperation to get the work
                going. We currently have implementing partners in 4 counties in
                Kenya and one partner in Uganda. If you wish to join us as an
                implementing partner, you can reach us by emailing{' '}
                <NavUlLink to={{}} rel="noopener noreferrer" target="_blank">
                  <a
                    rel="noopener noreferrer"
                    href="mailto:info@leptacommunity.org"
                    target="_blank"
                  >
                    info@leptacommunity.org
                  </a>
                </NavUlLink>{' '}
                where you will receive orientation on the process.
              </Text>
            </Box>
          </Stack>

          <LeapDivider />
          <Stack
            direction="row"
            className="leap-program-flex"
            style={{
              margin: '30px 0 40px',
              justifyContent: 'space-between'
            }}
          >
            <Box className="leap-program-img">
              <img
                src={'./images/learn_icon.png'}
                alt={'Logo'}
                className="get-img"
              />
            </Box>

            <Box className="leap-program-description">
              <Text>
                Do you want to undergo our training program?
                <br /> We currently have 3 programs and they all happen at
                different times of the year. If you wish to participate, send an
                email to the program director{' '}
                <NavUlLink to={{}} rel="noopener noreferrer" target="_blank">
                  <a
                    rel="noopener noreferrer"
                    href="mailto:seth@leptacommunity.org"
                    target="_blank"
                  >
                    seth@leptacommunity.org
                  </a>
                </NavUlLink>{' '}
                or call/message using +254 702 846 379 and you will receive
                guidance on the next steps.
              </Text>
            </Box>
          </Stack>
        </Box>
      </LeapContainer>
    </SectionDiv>
  )
}

export default GetInvolvedView
