import React from 'react'
import {
  LeapContainer,
  SectionDiv,
  Text,
  Title,
  LeapDivider,
  LeapGrid
} from './../../styles/LeapContainer.style'
import { LeapCommunityCard } from './../../components/index'
import { useTheme } from '@mui/material'
import { community } from './data/data'

function CommunityView() {
  const theme = useTheme()
  return (
    <main>
      <SectionDiv>
        <LeapContainer
          style={{
            padding: '40px 10px 10px'
          }}
        >
          <Title style={{ padding: '20px 0 20px' }} variant="h4">
            our community
          </Title>
          <Text variant="p">
            Lepta is a community of young people that has come together
            determined to transform our society. <br /> Our focus has been
            developing each individual member as a leader who will bring change
            in his/her community because we believe that Leadership is about
            enabling people to step beyond the present limitations and
            empowering them to impact others through their gifts, abilities and
            resources.
            <br />
            We are building a community where people can receive training and
            mentorship, access to resources and learning, get connections and
            find opportunities to be all they can be.
          </Text>

          <Title
            style={{
              margin: '20px 0',
              fontWeight: 'bold',
              fontFamily: 'Nexa-Bold',
              color: theme.palette.primary.main,
              fontSize: '1.2rem'
            }}
            className="purple"
          >
            alumni community brief
          </Title>
          <LeapDivider style={{ margin: '0 0 20px' }} />
          <LeapGrid>
            {community.map((value, index) => (
              <LeapCommunityCard key={index} data={value} />
            ))}
          </LeapGrid>
        </LeapContainer>
      </SectionDiv>
    </main>
  )
}

export default CommunityView
